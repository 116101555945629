// React
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Pages
import DefaultPage from "./pages/defaultPage/DefaultPage";
import LocationPage from "./pages/locationPage/LocationPage";
import CreateTask from "./pages/createTask/CreateTask";
import PickTimeslot from "./pages/pickTimeslot/PickTimeslot";
import TaskPropsPage from "./pages/taskPropsPage/TaskPropsPage";

// Stores
import { fetchPages, setActivePage } from "./store/pages/actions";
import { fetchProjectInfo } from "./store/project/actions";
import { getCurrentTemplate, getPages } from "./store/pages/selectors";
import { getProjectInfo } from "./store/project/selectors";

// Images
import ImgPoweredBy from "./img/powered_by_bessy.svg";
import PlanbotIcon from "./img/planbot_icon.svg";

import { getBackendHost } from "./config/Config";

import "./App.scss";
import "./common/scss/global.scss";
import ConfirmPage from "./pages/confirmpage/ConfirmPage";

export default function App() {
  const dispatch = useDispatch();

  const [pageComponent, setPageComponent] = useState("");
  const [appComponent, setAppComponent] = useState("");

  const { pathname: currentPath } = useLocation();

  const { projectInfo } = useSelector( getProjectInfo );

  const { pages } = useSelector( getPages );

  const template = useSelector( getCurrentTemplate );

  useEffect(() => {
    dispatch( fetchPages( currentPath ) );
    dispatch( fetchProjectInfo() );
  }, []);

  useEffect(() => {
    dispatch( setActivePage( currentPath ) );
  }, [ currentPath ]);

  useEffect(() => {
    if ( !template || !projectInfo || !projectInfo.enabled )
    {
      return;
    }

    switch (template) {
      case "location":
        setPageComponent(<LocationPage />);
        break;

      case "confirmTask":
        setPageComponent(<ConfirmPage />);
        break;

      case "createTask":
        if( !projectInfo.allowCreateTask )
        {
          return;
        }

        setPageComponent(<CreateTask />);
        break;

      case "pickTimeslot":
        setPageComponent(<PickTimeslot
          allowExtraTimeslots={projectInfo.allowExtraTimeslots}
          allowPreferedDate={projectInfo.allowPreferedDate}
          maxTimeslots={projectInfo.maxTimeslots} />);
        break;

      case "taskProps":
        if( !projectInfo.allowCreateTask )
        {
          return;
        }

        setPageComponent(<TaskPropsPage />);
        break;

      default:
        setPageComponent(<DefaultPage />);
        break;
    }

    const planbotDefaultBgColor = "#f2f2f2";

    const appStyle = {
      background: `${projectInfo.backgroundColor || planbotDefaultBgColor}`
    };

    let projectLogo;

    if( projectInfo.logo )
    {
      const host = getBackendHost();

      const logoUrl = `${host}/api/hk/media/get?path="${projectInfo.logo}"`;

      projectLogo =
        (<img src={logoUrl} alt="" className="project-logo" />);
    }

    setAppComponent(
      <div className="app no-select" style={appStyle}>
        <div className="header-bar">
          {projectLogo}
        </div>
        <div className="overlay">
          <div className="innerbox">
            {projectLogo}
            <img src={PlanbotIcon} alt="" className="planbot-icon" />
            {pageComponent}
          </div>
        </div>

        <a href="https://www.bessy.nl/afspraak-inplannen-door-klant" target="_blank"
           className="powered-by" >
          <img src={ImgPoweredBy} alt="" />
        </a>
      </div>
    );

  }, [ template, projectInfo, pages ]);

  return appComponent;
}
