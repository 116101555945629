// Helpers
import { expectString } from "../../helpers/Expect";
import { getBackendHost } from "../../config/Config";

// App actions
import { appLoading, appDoneLoading } from "../appState/actions";

// Dispatch types
export const FETCH_SUGGESTION_SUCCESS = "FETCH_SUGGESTION_SUCCESS";
export const REQUEST_SUGGESTION_SUCCESS = "REQUEST_SUGGESTION_SUCCESS";
export const RESET_SUGGESTION = "RESET_SUGGESTION";
export const RESCHEDULE = "RESCHEDULE";
export const UNSCHEDULE = "UNSCHEDULE";


export const GENERATE_EXTRA_TIMESLOTS_SUCCESS = "GENERATE_EXTRA_TIMESLOTS_SUCCESS";
export const CANCEL_SUGGESTION_SUCCESS = "CANCEL_SUGGESTION_SUCCESS";
export const PICK_TIMESLOT_SUCCESS = "PICK_TIMESLOT_SUCCESS";
export const RESCHEDULE_SUCCESS = "RESCHEDULE_SUCCESS";
export const UNSCHEDULE_SUCCESS = "UNSCHEDULE_SUCCESS";

const backendHost = getBackendHost();

// -------------------------------------------------------------------- Function

/**
 * Fetch suggestion
 *
 * @param  {String} id - Id of the form
 */
export const fetchSuggestion = ( { taskId, sessionId } ) => {
  return async ( dispatch, getState ) => {
    dispatch(appLoading());

    expectString( taskId, "Invalid [taskId]" );

    await dispatch({
      type: RESET_SUGGESTION,
      payload: {}
    });

    const path = `/api/website/suggestion/get`;
    let params = `?taskId=${taskId}`;

    const url = `${backendHost}${path}${params}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "hkd-sid": sessionId
        }
      });

      const {
        suggestion = {
          status: "not-found",
          reason: "suggestion-not-found",
        }
      } = await response.json();

      const { taskStatus, plannedDate } = suggestion;

      await dispatch({
        type: FETCH_SUGGESTION_SUCCESS,
        payload: { suggestion }
      });

      dispatch(appDoneLoading());

    } catch (err) {
      console.log(err);

      dispatch(appDoneLoading());
    }
  };
};

// -------------------------------------------------------------------- Function

/**
 * Request suggestion
 *
 * @param  {String} id - Id of the form
 */
export const requestSuggestion = ( { taskId, sessionId, startDate } ) => {
  return async ( dispatch, getState ) => {
    dispatch(appLoading());

    expectString( taskId, "Invalid [taskId]" );
    expectString( sessionId, "Invalid [sessionId]" );

    await dispatch({
      type: RESET_SUGGESTION,
      payload: {}
    });

    const path = `/api/website/suggestion/request`;
    let params = `?taskId=${taskId}`;

    if( startDate )
    {
      params += `&startDate=${startDate}`;
    }

    const url = `${backendHost}${path}${params}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "hkd-sid": sessionId
        }
      });

      const { suggestion } = await response.json();

      await dispatch({
        type: REQUEST_SUGGESTION_SUCCESS,
        payload: { suggestion }
      });

      dispatch(appDoneLoading());
    }
    catch (err)
    {
      console.log(err);
      dispatch(appDoneLoading());
    }
  };
};

// -------------------------------------------------------------------- Function

/**
 * Generate extra timeslots suggestion
 *
 * @param  {String} id - Id of the form
 */
export const generateExtraTimeslots = ( {
  suggestionId, comment, sessionId, preferredDate = null } ) => {
  return async ( dispatch, getState ) => {
    dispatch(appLoading());

    expectString( suggestionId, "Invalid [suggestionId]" );
    expectString( sessionId, "Invalid [sessionId]" );

    await dispatch({
      type: RESET_SUGGESTION,
      payload: {}
    });

    const path = `/api/website/suggestion/generate-extra`;
    let params = `?suggestionId=${suggestionId}&preferredDate=${preferredDate}`;

    if( comment )
    {
      comment = encodeURI(comment);
      params += `&comment=${comment}`;
    }

    const url = `${backendHost}${path}${params}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "hkd-sid": sessionId
        }
      });

      const { suggestion } = await response.json();

      await dispatch({
        type: GENERATE_EXTRA_TIMESLOTS_SUCCESS,
        payload: { suggestion }
      });

      dispatch(appDoneLoading());
    }
    catch (err)
    {
      console.log(err);
      dispatch(appDoneLoading());
    }
  };
};

// -------------------------------------------------------------------- Function

/**
 * Cancel suggestion
 *
 * @param  {String} id - Id of the form
 */
export const cancelSuggestion = ( {
  suggestionId, comment, sessionId } ) => {
  return async ( dispatch, getState ) => {
    dispatch(appLoading());

    await dispatch({
      type: RESET_SUGGESTION,
      payload: {}
    });

    expectString( suggestionId, "Invalid [suggestionId]" );
    expectString( sessionId, "Invalid [sessionId]" );

    const path = `/api/website/suggestion/cancel`;
    let params = `?suggestionId=${suggestionId}`;

    if( comment )
    {
      comment = encodeURI(comment);
      params += `&comment=${comment}`;
    }

    const url = `${backendHost}${path}${params}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "hkd-sid": sessionId
        }
      });

      const { suggestion } = await response.json();

      await dispatch({
        type: CANCEL_SUGGESTION_SUCCESS,
        payload: { suggestion }
      });

      dispatch(appDoneLoading());
    }
    catch (err)
    {
      console.log(err);
      dispatch(appDoneLoading());
    }
  };
};

// -------------------------------------------------------------------- Function

/**
 * Reschedule a task
 *
 * @param  {String} id - Id of the form
 */
export const reschedule = ( { taskId, sessionId } ) => {
  return async ( dispatch, getState ) => {
    dispatch(appLoading());

    await dispatch({
      type: RESCHEDULE,
      payload: {}
    });

    expectString( taskId, "Invalid [taskId]" );

    const path = `/api/website/task/reschedule`;
    let params = `?taskId=${taskId}`;

    const url = `${backendHost}${path}${params}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "hkd-sid": sessionId
        }
      });

      const { suggestion } = await response.json();

      await dispatch({
        type: RESCHEDULE_SUCCESS,
        payload: { suggestion }
      });

      dispatch(appDoneLoading());
    }
    catch (err)
    {
      console.log(err);
      dispatch(appDoneLoading());
    }
  };
};

// -------------------------------------------------------------------- Function

/**
 * Unschedule a task
 *
 * @param  {String} id - Id of the form
 */
export const unschedule = ( { taskId, sessionId } ) => {
  return async ( dispatch, getState ) => {
    dispatch(appLoading());

    await dispatch({
      type: UNSCHEDULE,
      payload: {}
    });

    expectString( taskId, "Invalid [taskId]" );

    const path = `/api/website/task/unschedule`;
    let params = `?taskId=${taskId}`;

    const url = `${backendHost}${path}${params}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "hkd-sid": sessionId
        }
      });

      const { suggestion } = await response.json();

      await dispatch({
        type: UNSCHEDULE_SUCCESS,
        payload: { suggestion }
      });

      dispatch(appDoneLoading());
    }
    catch (err)
    {
      console.log(err);
      dispatch(appDoneLoading());
    }
  };
};

// -------------------------------------------------------------------- Function

/**
 * Pick timeslot
 *
 * @param  {String} id - Id of the form
 */
export const pickTimeslot = ( {
  timeslotId, sessionId } ) => {
  return async ( dispatch, getState ) => {
    dispatch(appLoading());

    expectString( timeslotId, "Invalid [timeslotId]" );
    expectString( sessionId, "Invalid [sessionId]" );

    await dispatch({
      type: RESET_SUGGESTION,
      payload: {}
    });

    const path = `/api/website/suggestion/pick`;
    let params = `?timeslotId=${timeslotId}`;

    const url = `${backendHost}${path}${params}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "hkd-sid": sessionId
        }
      });

      const { suggestion } = await response.json();

      await dispatch({
        type: PICK_TIMESLOT_SUCCESS,
        payload: { suggestion }
      });

      dispatch(appDoneLoading());
    }
    catch (err)
    {
      console.log(err);
      dispatch(appDoneLoading());
    }
  };
};
