// App actions
import {
  FETCH_SUGGESTION_SUCCESS,
  REQUEST_SUGGESTION_SUCCESS,
  GENERATE_EXTRA_TIMESLOTS_SUCCESS,
  CANCEL_SUGGESTION_SUCCESS,
  PICK_TIMESLOT_SUCCESS,
  RESET_SUGGESTION,
  RESCHEDULE_SUCCESS,
  UNSCHEDULE_SUCCESS
} from "./actions";

const initialState = {};

/**
 * Fetch suggestion reducer
 * @param  {Object} [state=initialState] - Current state
 * @param  {String} type - Dispatch type
 * @param  {Object} payload
 *
 * @return {Object} - New state
 */
export default function suggestion(state = initialState, { type, payload }) {
  switch (type) {

    case FETCH_SUGGESTION_SUCCESS:
    case REQUEST_SUGGESTION_SUCCESS:
    case GENERATE_EXTRA_TIMESLOTS_SUCCESS:
    case CANCEL_SUGGESTION_SUCCESS:
    case PICK_TIMESLOT_SUCCESS:
    case RESCHEDULE_SUCCESS:
    {
      const { suggestion } = payload;

      if( suggestion.plannedDate )
      {
        const { plannedDate } = suggestion;

        let date =
          new Date(plannedDate).toLocaleDateString( "nl-NL",
          { weekday: "long",
            day: "numeric",
            month: "long" } );

        if( !date )
        {
          date = new Date(plannedDate).toDateString();
        }

        suggestion.humanPlannedDate = date;
      }

      return suggestion;
    }

    case RESET_SUGGESTION:
      return {}
      
    case UNSCHEDULE_SUCCESS:
      return { status: "cancelled", reason: "unschedule" };

    default:
      return { ...state };
  }
}
